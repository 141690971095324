import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { IPageService } from '../../services/PageService';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { IUser } from '@intouch/its.essential/app/essential/domain/access/User';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IChecklist } from '../../domain/checklists/Checklist';
import { ChecklistCreateModal } from './modals/ChecklistCreateModal';
import { TrialUpgradeModal } from './modals/TrialUpgradeModal';
import { ICheckApi } from '../../api/CheckApi';
import { IListingItem } from '../../domain/checklists/ListingItem';
import { AssignChecklistModal } from './modals/AssignChecklistModal';
import { ICheckSession } from '../../services/CheckSession';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IProductUrl } from '@intouch/its.essential/app/essential/domain/product/ProductMenuItemProvider';
import { TrialLimits } from '../../utils/TrialLimits';
import { IIqlApi } from '@intouch/its.essential/app/essential/api/IqlApi';
import { DatatableSearch } from '@intouch/its.essential/app/essential/domain/datatable/DatatableSearch';
import { ChecklistService } from '../../services/ChecklistService';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { ChecklistStatus } from '../../domain/checklists/enums/ChecklistStatus';
import { ChecklistUuidType } from '../../domain/checklists/enums/ChecklistUuidType';
import { DeleteChecklistModal } from './modals/DeleteChecklistModal';
import { IGroupButton } from '@intouch/its.essential/app/essential/components/button-group/GroupButton';
import { IUserSettingsService } from '@intouch/its.essential/app/essential/services/user/UserSettingsService';
import { StartAuditModal } from '../../modals/StartAuditModal';
import { ProductNavigationService } from '../../services/ProductNavigationService';
import { ShepherdService } from '../../services/ShepherdService';
import { TrialOnboardingTour } from '../../domain/tours/TrialOnboardingTour';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';

@Controller('its.check.module.checklists', ChecklistsListController.IID, ChecklistsListController)
class ChecklistsListController {
    static IID: string = 'ChecklistsListController';
    static $inject: Array<string> = [
        'iteToaster',
        '$state',
        'itcPageService',
        '$mdDialog',
        '$translate',
        'itcCheckApi',
        'itcCheckSession',
        'iteAccessService',
        'APPCONFIG',
        '$window',
        'iteIqlApi',
        'itcChecklistService',
        '$scope',
        'iteUserSettingsService',
        '$mdMedia',
        'itcProductNavigationService',
        'itcShepherdService',
        'iteLogger',
    ];

    protected checklists: Array<IListingItem>;
    protected pager: IPager;
    protected initialLoadError: boolean = false;
    protected currentSearchResults: string = 'pending_published';

    public loading: boolean = true;
    public datatableSearch: DatatableSearch = new DatatableSearch();

    // permissions
    public hasAclAdmin: boolean = false;
    public hasAclCreator: boolean = false;
    public hasAclAssignment: boolean = false;
    public isAdmin: boolean = false;
    public isIntouchUser: boolean = false;
    public checklistCount: number = 0;
    public urls: Array<IProductUrl>;
    public hasIq: boolean = false;

    public disableSortingName: boolean = false;
    public isDisabledPreset: boolean = false;

    public viewTypeButtons: Array<IGroupButton> = [];
    public viewType: string = null;

    private checklistResultSubscription: SubjectSubscription = null;

    constructor(
        private toaster: IToaster,
        private stateService: ng.ui.IStateService,
        private pageService: IPageService,
        private dialog: ng.material.IDialogService,
        private translate: angular.translate.ITranslateService,
        private checkApi: ICheckApi,
        private session: ICheckSession,
        private accessService: IAccessService,
        private appConfig: any,
        private window: ng.IWindowService,
        private iqlApi: IIqlApi,
        public checklistService: ChecklistService,
        private scope: any,
        private userSettingsService: IUserSettingsService,
        public mdMedia: any,
        private productNavigationService: ProductNavigationService,
        protected shepherdService: ShepherdService,
        private logger: ILogger
    ) {
        this.setViewTypeButtons(this.stateService.params['view']);
        this.datatableSearch.term = this.stateService.params['search'] || null;
        this.checklistResultSubscription = this.checklistService.checklistsResult.subscribe((checklistResult) => {
            if (checklistResult) {
                this.pager = checklistResult.pager;
                this.checklists = checklistResult.checklists;
                this.loading = false;
            }
        });

        this.scope.$on('$destroy', () => {
            if (this.checklistResultSubscription) {
                this.checklistResultSubscription.unsubscribe();
                this.checklistResultSubscription = null;
            }
        });

        // set permissions
        let user: IUser = this.session.getToken().getUser();
        this.hasAclAdmin = user.hasAcl('check_admin');
        this.hasAclCreator = user.hasAcl('checklist_creator');
        this.hasAclAssignment = user.hasAcl('check_approval_assignment');
        this.isAdmin = user.isAdmin();
        this.isIntouchUser = user.isIntouchUser();
        this.hasIq = this.accessService.getToken().getProductsSlugs().indexOf('intelligence') > -1;

        this.buildPager();
        this.getChecklistTotal();
        this.load();

        const presetName: string = this.stateService.params['presetName'];
        this.isDisabledPreset = presetName === 'disabled';
    }

    public load(): void {
        this.loading = true;
        const presetName: string = this.stateService.params['presetName'];
        this.isDisabledPreset = presetName === 'disabled';
        this.checklistService.loadChecklistsByPreset(presetName, this.datatableSearch.term, this.pager).finally(() => {
            if (this.shouldShowTrialOnboardingTour()) {
                this.showTrialOnboardingTour();
            }
            this.loading = false;
        });
    }

    public viewChecklistRecords(checklist: IListingItem, event: any): void {
        event.stopPropagation();
        this.productNavigationService.viewChecklistRecords(checklist.originalUuid);
    }

    /**
     * Get total number of checklists created by a trial user
     *
     */
    public getChecklistTotal(): void {
        if (this.accessService.isProductTrial('check')) {
            this.checkApi
                .findChecklists(this.pager, this.datatableSearch.term, null, ['scheduler'])
                .then((results: PagedEntities) => {
                    this.checklistCount = results.getEntities().length;
                })
                .catch((error) => {
                    this.initialLoadError = true;
                    this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_LOAD');
                });
        }
    }

    public startCheck(checklist: IListingItem, event: any): void {
        event.stopPropagation();
        this.checkApi.findChecklist(checklist.uuid).then((result) => {
            this.dialog.show(
                StartAuditModal.instantiate({
                    locals: {
                        checklist: result,
                    },
                })
            );
        });
    }

    /**
     * Determines if we are loading content or not
     *
     * @returns {boolean}
     */
    public isLoading(): boolean {
        return this.pageService.loaderVisible;
    }

    /**
     * Allows a user to edit a given group (work off a copy until save)
     *
     * @param checklist
     */
    public edit(checklist: IListingItem): void {
        this.stateService.go('home.checklists.edit', { uuid: checklist.uuid });
    }

    /**
     * Allows a user to publish a new version of a checklist
     *
     * @param checklist
     * @param event
     */
    public publish(checklist: IListingItem, event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.checkApi
            .publishChecklist(checklist.uuid)
            .then((result: IChecklist) => {
                checklist.status = result.status;
                this.load();
                this.toaster.success('CHECKLISTS.MESSAGES.NEW_VERSION');
            })
            .catch((error) => {
                this.toaster.error('CHECKLISTS.ERRORS.UNABLE_TO_PUBLISH');
            });
    }

    /**
     * Presents a modal to assign a user to a checklist
     *
     * @param {IListingItem} checklist
     * @param event
     */
    public assign(checklist: IListingItem, event: any): void {
        event.preventDefault();
        event.stopPropagation();

        this.dialog.show(
            AssignChecklistModal.instantiate({
                locals: {
                    selectedChecklist: checklist,
                    allowChecklistSelection: false,
                },
            })
        );
    }

    /**
     * Check if trial user is over the limit for allowable checklists
     *
     */
    public exceededTrialLimits(): boolean {
        return TrialLimits.checkTrialLimits(this.accessService.isProductTrial('check'), this.checklistCount);
    }

    /**
     * Show trial upgrade dialog
     *
     */
    public upgradeModal(): void {
        this.translate([
            'CHECKLISTS.TRIAL_ACCOUNT.TITLE',
            'CHECKLISTS.TRIAL_ACCOUNT.DESCRIPTION',
            'CHECKLISTS.TRIAL_ACCOUNT.UPGRADE',
            'CHECKLISTS.TRIAL_ACCOUNT.CANCEL',
            'CHECKLISTS.TRIAL_ACCOUNT.OK',
            'CHECKLISTS.TRIAL_ACCOUNT.CONTACT_ADMIN',
        ]).then((translations) => {
            this.dialog
                .show(
                    TrialUpgradeModal.instantiate({
                        locals: {
                            title: translations['CHECKLISTS.TRIAL_ACCOUNT.TITLE'],
                            description: translations['CHECKLISTS.TRIAL_ACCOUNT.DESCRIPTION'],
                            contactAdmin: translations['CHECKLISTS.TRIAL_ACCOUNT.CONTACT_ADMIN'],
                            confirmText: translations['CHECKLISTS.TRIAL_ACCOUNT.UPGRADE'],
                            cancelText: translations['CHECKLISTS.TRIAL_ACCOUNT.CANCEL'],
                            okText: translations['CHECKLISTS.TRIAL_ACCOUNT.OK'],
                            isAdminOrIntouch: this.isAdmin || this.isIntouchUser,
                        },
                    })
                )
                .then((result) => {
                    if (result) {
                        this.window.location.href = this.appConfig.products.urls.access.root + 'settings/subscriptions';
                    }
                });
        });
    }

    /**
     * Opens a modal to duplicate a checklist
     *
     * @param {IListingItem} checklist
     */
    public duplicate(checklist: IListingItem): void {
        if (this.exceededTrialLimits()) {
            this.upgradeModal();
        } else {
            this.dialog
                .show(
                    ChecklistCreateModal.instantiate({
                        locals: {
                            sourceChecklist: checklist,
                        },
                    })
                )
                .then((result: IChecklist) => {
                    this.stateService.go('home.checklists.edit', { uuid: result.uuid });
                });
        }
    }

    /**
     * Will disable the given checklist
     *
     * @param checklist
     */
    public disable(checklist: IListingItem): void {
        this.translate(['CHECKLISTS.DISABLE_CHECKLIST', 'CHECKLISTS.MESSAGES.CONFIRM_DISABLE']).then((translations) => {
            this.dialog
                .show(
                    Confirm.instantiate({
                        locals: {
                            title: translations['CHECKLISTS.DISABLE_CHECKLIST'],
                            description: translations['CHECKLISTS.MESSAGES.CONFIRM_DISABLE'],
                            warning: translations['CHECKLISTS.DELETE_SCHEDULED'],
                            confirmText: translations['CHECKLISTS.DISABLE_CHECKLIST'],
                            confirmButtonCssClass: 'its-btn--delete',
                        },
                    })
                )
                .then((result) => {
                    if (result) {
                        this.checkApi
                            .disableChecklist(checklist.uuid)
                            .then((results: IChecklist) => {
                                checklist.active = results.active;
                                this.load();
                                this.toaster.info('CHECKLISTS.MESSAGES.DISABLE');
                            })
                            .catch((error) => {
                                this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_DISABLE');
                            });
                    }
                });
        });
    }

    /**
     * Will enable the given checklist
     *
     * @param checklist
     */
    public enable(checklist: IListingItem): void {
        this.translate(['CHECKLISTS.ENABLE_CHECKLIST', 'CHECKLISTS.MESSAGES.CONFIRM_ENABLE']).then((translations) => {
            this.dialog
                .show(
                    Confirm.instantiate({
                        locals: {
                            title: translations['CHECKLISTS.ENABLE_CHECKLIST'],
                            description: translations['CHECKLISTS.MESSAGES.CONFIRM_ENABLE'],
                        },
                    })
                )
                .then((result) => {
                    if (result) {
                        this.checkApi
                            .enableChecklist(checklist.uuid)
                            .then((results: IChecklist) => {
                                checklist.active = results.active;
                                this.load();
                                this.toaster.success('CHECKLISTS.MESSAGES.ENABLE');
                            })
                            .catch((error) => {
                                this.toaster.error('CHECKLISTS.ERRORS.UNABLE_TO_ENABLE');
                            });
                    }
                });
        });
    }

    /**
     * Will delete the given checklist
     *
     * @param checklist
     */
    public remove(checklist: IListingItem): void {
        this.translate([
            'CHECKLISTS.MESSAGES.CONFIRM_REMOVE_DESC',
            'GENERAL.CANCEL',
            'CHECKLISTS.DELETE_CHECKLIST',
            'CHECKLISTS.DELETE_SCHEDULED',
        ]).then((translations) => {
            this.dialog.show(DeleteChecklistModal.instantiate({
                locals: {
                    name: checklist.name,
                },
            })).then((result) => {
                if (result) {
                    let type: string =
                        checklist.status === ChecklistStatus.Published
                            ? ChecklistUuidType.Revision
                            : ChecklistUuidType.Original;
                    checklist.active = false;

                    this.checkApi
                        .deleteChecklist(checklist.uuid, type)
                        .then((response) => {
                            this.checklistCount--;
                            this.load();
                            this.toaster.info('CHECKLISTS.MESSAGES.DELETED');
                        })
                        .catch((error) => {
                            this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_DELETE');
                        });
                }
            });
        });
    }

    /**
     * Will delete the given checklist
     *
     * @param checklist
     */
    public removePending(checklist: IListingItem): void {
        this.translate([
            'CHECKLISTS.MESSAGES.CONFIRM_REMOVE_DRAFT_DESC',
            'GENERAL.CANCEL',
            'CHECKLISTS.DELETE_CHECKLIST_DRAFT',
        ]).then((translations) => {
            this.dialog
                .show(
                    Confirm.instantiate({
                        locals: {
                            title: translations['CHECKLISTS.DELETE_CHECKLIST_DRAFT'],
                            description: translations['CHECKLISTS.MESSAGES.CONFIRM_REMOVE_DRAFT_DESC'],
                            confirmText: translations['CHECKLISTS.DELETE_CHECKLIST_DRAFT'],
                            cancelText: translations['GENERAL.CANCEL'],
                            confirmButtonCssClass: 'its-btn--delete',
                        },
                    })
                )
                .then((result) => {
                    if (result) {
                        checklist.active = false;
                        this.checkApi
                            .deleteChecklist(checklist.uuid)
                            .then((response) => {
                                this.checklistCount--;
                                this.load();
                                this.toaster.info('CHECKLISTS.MESSAGES.DRAFT_DELETED');
                            })
                            .catch((error) => {
                                this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_DELETE_DRAFT');
                            });
                    }
                });
        });
    }

    public create(): void {
        if (this.exceededTrialLimits()) {
            this.upgradeModal();
            return;
        }

        this.stateService.go('home.checklists.create');
    }

    /**
     * Allow a user to toggle the sort order of a column
     *
     * @param field
     */
    public toggleSort(field: string): void {
        if (this.pager.isCurrentSort(field)) {
            this.pager.toggleSortOrder();
        }
        this.pager.sortBy = field;
        this.goToSelf(this.pager);
    }

    /**
     * Allow a user to go to the next page of submissions
     */
    public next(): void {
        if (this.pager.canGoNext()) {
            this.pager.currentPage++;
            this.goToSelf(this.pager);
        }
    }

    /**
     * Allow a user to go to the previous page of submissions
     */
    public prev(): void {
        if (this.pager.canGoPrevious()) {
            this.pager.currentPage--;
            this.goToSelf(this.pager);
        }
    }

    /**
     * Allow a user to search for users by name
     */
    public search(): void {
        this.checklists = [];
        this.pager.currentPage = 1; // reset back to the first page
        this.goToSelf(this.pager);
    }

    /**
     * Filter the returned results based on the selection from the drop down menu
     *
     * @param {string} results
     */
    public filterResults(results: string): void {
        this.currentSearchResults = results;
        this.pager.currentPage = 1;
        this.goToSelf(this.pager);
    }

    /**
     * Return the current filter label to be used in the drop down menu
     *
     * @returns {string}
     */
    public getFilterLabel(): string {
        return this.currentSearchResults;
    }

    /**
     * Decides whether we should show the checklist triple dot menu.
     *
     * @param {IListingItem} checklist
     */
    public shouldShowTripleDot(checklist: IListingItem): boolean {
        // if they're not an admin but the checklist is assignable, allow them to choose the 'assign' option
        if (!this.hasAclAdmin && checklist.isAssignable()) {
            return true;
        }

        // otherwise, show based on if they're an admin or creator or assigner
        return this.hasAclAdmin || this.hasAclCreator || this.hasAclAssignment;
    }

    /**
     * Returns true if the checklist was created by the active user
     *
     * @param {IListingItem} checklist
     * @returns {boolean}
     */
    public isCreatedByActiveUser(checklist: IListingItem): boolean {
        return checklist.createdByUuid === this.session.getUser().uuid;
    }

    /**
     * Returns whether the user is allowed to delete checklists or not
     *
     * @param {IListingItem} checklist
     * @returns {boolean}
     */
    public canRemove(checklist: IListingItem): boolean {
        return ((this.hasAclCreator || this.hasAclAdmin) && this.isCreatedByActiveUser(checklist)) || this.isAdmin;
    }

    /**
     * Navigate to the checklist dashboard in IQ for the given checklist originalUuid
     *
     * @param {string} originalUuid
     */
    public goToDashboard(originalUuid: string): void {
        let url: string =
            this.appConfig.products.urls.intelligence.app +
            this.accessService.getToken().getRawToken() +
            '?redirect=/analytics/standard/check/' +
            originalUuid;

        window.open(url, '_blank');
    }

    /**
     * Open a new tab to the Quick Start Guide article
     */
    public openQuickStartGuide(): void {
        let guideUrl: string;

        switch (this.accessService.getToken().getUser().language) {
            case 'fr_CA':
                // french url will be provided in the near future so leaving this switch/case here for now.
                guideUrl = 'https://intouchcheck.zendesk.com/hc/en-us/articles/360045161513-Check-Quick-Start-Guide';
                break;
            default:
                guideUrl = 'https://intouchcheck.zendesk.com/hc/en-us/articles/360045161513-Check-Quick-Start-Guide';
                break;
        }

        this.window.open(guideUrl, '_blank');
    }

    /**
     * Determine if header rows should be hidden due to empty intro state being shown
     */
    public hideForEmptyIntroState(): boolean {
        return (
            (!this.checklists || this.checklists.length === 0) &&
            !this.isDisabledPreset &&
            !this.datatableSearch.hasSearched
        );
    }

    public hasError(): boolean {
        return this.checklistService.hasError || this.initialLoadError;
    }

    private goToSelf(pager: IPager): void {
        this.stateService.go(
            this.stateService.current.name,
            {
                search: this.datatableSearch.term,
                page: pager.currentPage,
                sort_by: pager.sortBy,
                order: pager.order,
            },
            {
                notify: false, // prevent the events onStart and onSuccess from firing
                reload: false, // prevent reload of the current state
                location: 'replace', // replace the last record when changing the params so you don't hit the back button and get old params
                inherit: true, // inherit the current params on the url
            }
        );
        this.load();
    }

    private buildPager(): void {
        this.pager = Pager.make(
            this.stateService.params['page'],
            this.stateService.params['sort_by'],
            this.stateService.params['order']
        );
        if (this.translate.use() === 'fr_CA') {
            this.disableSortingName = true;
            this.pager.sortBy = 'original_created_at';
            this.pager.order = 'desc';
        }
    }

    private setViewTypeButtons(id: string): void {
        let onClick: (button: IGroupButton) => void = (button: IGroupButton) => {
            this.viewType = button.id;
            this.setStoredViewType(button.id);
        };

        this.viewTypeButtons = [
            {
                id: 'grid',
                icon: 'view_module',
                label: 'GENERAL.GRID',
                onClick: onClick,
            },
            {
                id: 'list',
                icon: 'view_list',
                label: 'GENERAL.LIST',
                onClick: onClick,
            },
        ];

        if (['grid', 'list'].indexOf(id) === -1) {
            id = this.getStoredViewType();
        }

        this.viewType = id;
    }

    private getStoredViewType(): string {
        return this.userSettingsService.getSetting('checklists.view_type') || 'grid';
    }

    private setStoredViewType(value: string): void {
        this.userSettingsService.storeSetting('checklists.view_type', value);
    }

    private shouldShowTrialOnboardingTour(): boolean {
        return (
            !this.shepherdService.hasSeenTour(TrialOnboardingTour.tourLocalStorageId) &&
            this.accessService.isProductTrial('check')
        );
    }

    private showTrialOnboardingTour(): void {
        const trialOnboardingTour: TrialOnboardingTour = new TrialOnboardingTour(this.translate);
        try {
            const tour: any = this.shepherdService.createTour(trialOnboardingTour, this.scope);
            tour.start();
        } catch (error) {
            this.logger.error('Failed to start Trial Onboarding Tour', error);
        }
    }
}
